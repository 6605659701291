import { Order } from 'models/Orders/Order';
import { QueryFilters } from 'models/QueryFilters';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { PagedResponse } from 'models/Responses/PagedResponse';

export type ExecutionsActions =
  | ExecutionsQueryRequested
  | ExecutionsQueried
  | ExecutionsQueryFailed
  | ExecutionsCsvRequested
  | ExecutionsCsvExported
  | ExecutionsCsvExportFailed;

export const EXECUTIONS_QUERY_REQUESTED = '[Executions API] Query requested';
export const executionsQueryRequested = (exchange: string, page: number, size: number, filters: QueryFilters) =>
  ({ type: EXECUTIONS_QUERY_REQUESTED, exchange, page, size, filters } as const);
export type ExecutionsQueryRequested = ReturnType<typeof executionsQueryRequested>;

export const EXECUTIONS_QUERIED = '[Executions API] Queried';
export const executionsQueried = (response: PagedResponse<Order>) => ({ type: EXECUTIONS_QUERIED, response } as const);
export type ExecutionsQueried = ReturnType<typeof executionsQueried>;

export const EXECUTIONS_QUERY_FAILED = '[Executions API] Query failed';
export const executionsQueryFailed = (error: DcErrorResponse) => ({ type: EXECUTIONS_QUERY_FAILED, error } as const);
export type ExecutionsQueryFailed = ReturnType<typeof executionsQueryFailed>;

export const EXECUTIONS_CSV_REQUESTED = '[Executions API] CSV requested';
export const executionsCsvRequested = (
  exchange: string,
  headers: string[],
  filters: QueryFilters,
  appliedTimeZone?: string,
) => ({ type: EXECUTIONS_CSV_REQUESTED, exchange, headers, filters, appliedTimeZone } as const);
export type ExecutionsCsvRequested = ReturnType<typeof executionsCsvRequested>;

export const EXECUTIONS_CSV_EXPORTED = '[Executions API] CSV exported';
export const executionsCsvExported = () => ({ type: EXECUTIONS_CSV_EXPORTED } as const);
export type ExecutionsCsvExported = ReturnType<typeof executionsCsvExported>;

export const EXECUTIONS_CSV_EXPORT_FAILED = '[Executions API] CSV export failed';
export const executionsCsvExportFailed = (error: DcErrorResponse) =>
  ({ type: EXECUTIONS_CSV_EXPORT_FAILED, error } as const);
export type ExecutionsCsvExportFailed = ReturnType<typeof executionsCsvExportFailed>;
