import { combineEpics, Epic, ofType } from 'redux-observable';
import { switchMap, catchError, map, of, tap } from 'rxjs';
import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import {
  EXECUTIONS_QUERY_REQUESTED,
  ExecutionsQueryRequested,
  executionsQueried,
  executionsQueryFailed,
  executionsCsvExported,
  executionsCsvExportFailed,
  ExecutionsCsvRequested,
  EXECUTIONS_CSV_REQUESTED,
} from './executions.actions';
import { executionsApiConnector, ExecutionsApi } from 'api/executions';
import { downloadBlob } from 'utils/blob/blob';
import { httpClient } from 'utils/http/httpClient';

const executionsApi$ = executionsApiConnector(httpClient);

export const executionsQueryRequestedEpic =
  (api$: ExecutionsApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(EXECUTIONS_QUERY_REQUESTED),
      switchMap(({ exchange, page, size, filters }: ExecutionsQueryRequested) =>
        api$.query(exchange, page, size, filters).pipe(
          map(executionsQueried),
          catchError((err: DcErrorResponse) => of(executionsQueryFailed(err))),
        ),
      ),
    );

export const executionsCsvRequestedEpic =
  (api$: ExecutionsApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(EXECUTIONS_CSV_REQUESTED),
      switchMap(({ exchange, headers, filters, appliedTimeZone }: ExecutionsCsvRequested) =>
        api$.export(exchange, filters, appliedTimeZone).pipe(
          tap(({ filename, payload }) => {
            const appendHeaders = new Blob([headers.join(',').concat('\n'), payload]);
            downloadBlob(appendHeaders, filename);
          }),
          map(executionsCsvExported),
          catchError((err: DcErrorResponse) => of(executionsCsvExportFailed(err))),
        ),
      ),
    );

export const executionsEpics = (): Epic =>
  combineEpics(executionsQueryRequestedEpic(executionsApi$), executionsCsvRequestedEpic(executionsApi$));
