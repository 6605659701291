import { useMemo } from 'react';
import { MessageDescriptor, useIntl, IntlShape as ReactIntlShape } from 'react-intl';
import { I18nKeys } from 'intl/i18nKeys';

export interface I18nValues {
  dateTime?: 'local' | 'utc' | 'exchange';
  count?: number;
  totalEntries?: string;
  statusCode?: number;
  exchange?: string;
  correlationId?: string;
  from?: string;
  to?: string;
}

export const defaultI18nValues: I18nValues = { dateTime: 'local' };

export interface IntlShape extends ReactIntlShape {
  formatMessage: (descriptor: MessageDescriptor, values?: I18nValues) => string;
}

export const useI18n = () => {
  const intl = useIntl();
  return {
    ...intl,
    formatMessage: (descriptor: MessageDescriptor, values: I18nValues = {}) =>
      intl.formatMessage(descriptor, { ...defaultI18nValues, ...values }),
  } as IntlShape;
};

export const useI18nMessage = (key: I18nKeys, values: I18nValues = {}): string => {
  const intl = useIntl();
  return useMemo(() => intl.formatMessage({ id: key }, { ...values }), [intl, key, values]);
};

export const useI18nMessages = (keys: I18nKeys[], values: I18nValues = {}): string[] => {
  const intl = useIntl();
  return useMemo(() => keys.map(k => intl.formatMessage({ id: k }, { ...values })), [keys, intl, values]);
};
