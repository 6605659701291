import { zonedTimeToUtc } from 'date-fns-tz';
import { isUndefined } from 'lodash';
import queryString, { ParsedQuery } from 'query-string';
import { FilterType, QueryFilters } from 'models/QueryFilters';
import { DEFAULT_LOCAL_TIMEZONE } from 'utils/dates/dates';
import { isDefinedAndNotEmpty } from 'utils/string/string';

const filterEmptyUndefinedValues = (str: string | undefined | null): string | undefined =>
  isDefinedAndNotEmpty(str) ? str : undefined;

// TODO:: Unit test
export function getPagedQueryString(
  page: number,
  size: number,
  filters: QueryFilters,
  appliedTimeZone?: string,
): string {
  const params = getPagedQueryParam(page, size, filters, appliedTimeZone);
  return queryString.stringify(params);
}

export const getDataQueryStringForApiRequest = (filters: QueryFilters): string => {
  const params = getDataQueryParamFromFilters(filters, true);
  return queryString.stringify(params);
};

export const getDataQueryStringForUrlUpdateOnly = (filters: QueryFilters): string => {
  const params = getDataQueryParamFromFilters(filters, false);
  return queryString.stringify(params, { arrayFormat: 'comma' });
};

function getDataQueryParamFromFilters(filters: QueryFilters, forApiRequest = true, appliedTimeZone?: string) {
  if (forApiRequest) {
    return {
      account: filterEmptyUndefinedValues(filters.account),
      customer: filterEmptyUndefinedValues(filters.customer),
      endDate: filters.businessDates?.to?.toISOString(),
      exchangeOrderId: filterEmptyUndefinedValues(filters.exchangeOrderId),
      price: filters.price,
      product: filterEmptyUndefinedValues(filters.product),
      productDescription: filterEmptyUndefinedValues(filters.productDescription),
      quantity: filters.quantity,
      side: filters.side?.length === 1 ? filters.side[0] : undefined,
      startDate: filters.businessDates?.from?.toISOString(),
      functionalStatus: filters.functionalStatus ?? undefined,
      tradingSystem: filterEmptyUndefinedValues(filters.tradingSystem),
      customerSystemId: filterEmptyUndefinedValues(filters.customerSystemId),
      userSystemId: filterEmptyUndefinedValues(filters.userSystemId),
      timeInForce: filters.timeInForce ?? undefined,
      timeZone: appliedTimeZone,
    };
  }

  return {
    exchange: filterEmptyUndefinedValues(filters.exchange),
    exchangeOrderId: filterEmptyUndefinedValues(filters.exchangeOrderId),
    startDate: filters.businessDates?.from?.toISOString(),
    endDate: filters.businessDates?.to?.toISOString(),
    functionalStatus: filters.functionalStatus,
    side: filters.side,
    product: filterEmptyUndefinedValues(filters.product),
    productDescription: filterEmptyUndefinedValues(filters.productDescription),
    quantity: filters.quantity,
    price: filters.price,
    timeInForce: filters.timeInForce,
  };
}

function getPagedQueryParam(page: number, size: number, filters: QueryFilters, appliedTimeZone?: string) {
  return {
    ...getDataQueryParamFromFilters(filters, true, appliedTimeZone),
    page,
    size,
  };
}

export const getParsedUrlParams = (rawUrlParams: string): ParsedQuery<string> =>
  queryString.parse(rawUrlParams, { arrayFormat: 'comma' });

export function convertUrlParamsToFilters(urlParams: queryString.ParsedQuery, appliedTimeZone?: string): QueryFilters {
  const effectiveTimeZone = appliedTimeZone ?? DEFAULT_LOCAL_TIMEZONE;

  const queryFilters = {
    ...urlParams,
    functionalStatus: adaptUrlParamByFilterType(urlParams.functionalStatus, 'functionalStatus'),
    side: adaptUrlParamByFilterType(urlParams.side, 'side'),
    timeInForce: adaptUrlParamByFilterType(urlParams.timeInForce, 'timeInForce'),
    businessDates:
      !isUndefined(urlParams.startDate) && !isUndefined(urlParams.endDate)
        ? {
            from:
              !Array.isArray(urlParams.startDate) && isDefinedAndNotEmpty(urlParams.startDate)
                ? zonedTimeToUtc(urlParams.startDate, effectiveTimeZone)
                : undefined,
            to:
              !Array.isArray(urlParams.endDate) && isDefinedAndNotEmpty(urlParams.endDate)
                ? zonedTimeToUtc(urlParams.endDate, effectiveTimeZone)
                : undefined,
          }
        : undefined,
  } as QueryFilters;

  return Object.fromEntries(
    Object.entries(queryFilters).filter(([key, value]) => isFilterType(key) && isDefinedAndNotEmpty(value)),
  );
}

function adaptUrlParamByFilterType<T = string>(
  urlParam: string | (string | null)[] | null,
  filterType: FilterType,
): T | undefined {
  switch (filterType) {
    case 'functionalStatus':
      if (!urlParam) {
        return undefined;
      }
      return Array.isArray(urlParam) ? (urlParam as T) : ([urlParam] as T);
    case 'side':
    case 'timeInForce':
      if (!urlParam) {
        return undefined;
      }
      return Array.isArray(urlParam)
        ? (urlParam.filter(isDefinedAndNotEmpty).map(value => value.toLocaleUpperCase()) as T)
        : ([urlParam.toLocaleUpperCase()] as T);
    default:
      return urlParam as T;
  }
}

export function isFilterType(arg: string): arg is FilterType {
  return [
    'account',
    'businessDates',
    'customer',
    'exchange',
    'exchangeOrderId',
    'price',
    'product',
    'productDescription',
    'quantity',
    'side',
    'functionalStatus',
    'tradingSystem',
    'customerSystemId',
    'userSystemId',
    'timeInForce',
  ].includes(arg);
}
